import React from 'react';
import { Icon, Flex, Text } from '@chakra-ui/react';
import { GrAlert } from 'react-icons/gr';
import Button from 'components/Button';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

const ErrorPage = () => {
  const router = useRouter();
  return (
    <React.Fragment>
      <NextSeo title="Ops!" />
      <Flex alignItems="center" justifyContent="center" mt={4} flexDir="column">
        <Icon as={GrAlert} boxSize={48} color="red.500" />
        <Flex flexDir="column" textAlign="center">
          <Text fontSize="xx-large" fontWeight="bold">
            Ops!
          </Text>
          <Text fontSize="x-large">
            Página não encontrada! Verifique se você digitou a URL corretamente.
          </Text>
        </Flex>
        <Flex mt={4}>
          <Button
            colorScheme="brand"
            mr={4}
            onClick={() => {
              router.push('/');
            }}
          >
            Ir para o Painel
          </Button>

          <Button
            colorScheme="brand"
            onClick={() => {
              router.push('/login');
            }}
          >
            Fazer login novamente
          </Button>
        </Flex>
      </Flex>
    </React.Fragment>
  );
};

export default ErrorPage;
